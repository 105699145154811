// src/utils/loadAssets.ts
// export function loadAssets(context: __WebpackModuleApi.RequireContext) {
//     return context.keys().reduce((acc, key) => {
//         const assetName = key.replace('./', '').replace(/\.(png|mp4|webp|ics|svg|jpg|jpeg)$/, '');
//         acc[assetName] = context(key);
//         return acc;
//     }, {} as { [key: string]: string });
// }


// src/utils/loadAssets.ts
export function loadAssets(context: __WebpackModuleApi.RequireContext) {
    return context.keys().reduce((acc, key) => {
        const assetName = key.replace('./', '').replace(/\.(png|mp4|webp|ics|svg|jpg|jpeg)$/, '');

        // Check if the file is an SVG
        if (key.endsWith('.svg')) {
            // Import the SVG as a React component
            acc[assetName] = context(key).default;
        } else {
            // Import other assets normally
            acc[assetName] = context(key);
        }
        return acc;
    }, {} as { [key: string]: any });
}
