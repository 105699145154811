// pages/Women.tsx
import React from 'react';
import { Container, Row, Col, Button, Carousel, Image, Card } from 'react-bootstrap';
import { womenAssets, report } from '../assets'
import { logo } from '../assets'

const CardsHorizontal = report['cards-horizontal-women-animated'];
const Women: React.FC = () => {
    return (
        <>
            {/* Hero */}
            <Container className='ombre-01 home-section' fluid>
                <Row className="ashera-row mt-5 align-items-center text-center">
                    <Col lg={6} className="order-lg-1 order-2" >
                        <h2 style={{ fontSize: '3rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Your Health, Your Future - Take Control Today</h2>
                        <p style={{ fontSize: '1.2rem', color: 'var(--darker-purple)' }}>
                            Discover hidden toxins and health markers now, before they impact your future—and the well-being of those you care about most
                        </p>
                        <Button target='_blank' variant="primary" size="lg" className='cta-button' href='https://forms.fillout.com/t/jeUeErZTb3us'>
                            Join Our Waitlist
                        </Button>
                    </Col>
                    <Col lg={6} className="order-lg-2 order-1 mb-4 mb-lg-0 text-center">
                        <Carousel className='hero-carousel'>
                            {Array.from({ length: 2 }).map((_, index) => (
                                <Carousel.Item key={index} >
                                    <Image
                                        src={womenAssets['carousel/' + index.toString()]}
                                        alt={`Health Illustration ${index}`}
                                        rounded
                                        className='hero-image'
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <CardsHorizontal
                            className='hero-cards-horizonal'
                        />
                    </Col>
                </Row>
            </Container>

            {/* Reproductive Health */}
            <Container className='background-01 home-section' fluid style={{ minHeight: '50vh' }}>
                <h2 className="text-center" style={{ color: '#4B0082', fontWeight: 'bold', marginBottom: '3rem' }}>
                    Reproductive Health
                </h2>
                <Row className="ashera-row justify-content-center">
                    <p>Early detection and management of environmental toxin can significantly improve health outcomes, particularly concerning reproductive health.
                        Women face unique health risk from forever chemicals, microplastics, pesticides, hormone disruptors, mold exposure
                        and key health biomarker. Your health is more than a priority—it’s the foundation for your future and the well-being of those you care about.
                        Modern women face unique risks from hidden toxins and chemicals that are only detectable with recent scientific advances.
                        Traditional tests often overlook these dangers, but our health markers and lifestyle metrics can reveal your risks and where they might be coming from.
                    </p>
                </Row>
                <Row className="ashera-row justify-content-center">
                    <Col md={4} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/toxins']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Toxin Exposure</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Identifying chemicals and toxins that could affect fetal and early childhood development, with personalized detoxification recommendations.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/nutrition']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Nutritional Insights</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Detecting nutritional deficiencies and optimizing nutrient absorption before or during pregnancy and postpartum.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/early-disease']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Early Disease Detection</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Identifying early stages of gestational diabetes, preeclampsia, and other complications for timely intervention.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/functional-testing']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Functional Testing</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Understanding metabolic and biochemical imbalances.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/gut']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Gut & Digestive Health</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Assessing gut microbiome composition and improving digestive health.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/balance']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Stress & Mental Management</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Revealing metabolic imbalances related to mental health.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={womenAssets['icons/hormones']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}> Hormonal and Reproductive Health</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Detecting hormonal imbalances and aiding in diagnosing conditions like PCOS, endometriosis, and infertility.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container >

            {/* Knowledge is Power */}
            <Container className='background-02 home-section roadmap' fluid style={{ minHeight: '35vh' }}>

                <Row className="ashera-row justify-content-center">
                    <Col md={12} className="mb-4" >
                        <Card className="h-100" style={{ border: '1px solid #fafafa', borderRadius: '10px', padding: '2rem', backgroundColor: 'var(--darker-purple)' }}>
                            <Card.Body>
                                {/* <Card.Img
                    src={logo['darker-purple']}
                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                    alt="Icon"
                /> */}
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: '#fafafa' }}>Knowledge is Power</Card.Title>
                                <Card.Text style={{ color: '#fafafa', fontSize: '1rem' }}>
                                    Your health journey is as unique as you are. With Ashera Health,
                                    you gain access to powerful, personalized insights that empower you to take charge of your well-being.
                                    Our community is dedicated to helping you make informed decisions that protect your future—and those you love.
                                    <br /><br />
                                    Take the first step towards a healthier future. Join the Ashera Health waitlist today
                                    to access exclusive, science-backed health insights tailored just for you.
                                </Card.Text>
                            </Card.Body>



                        </Card>
                    </Col>
                </Row>
            </Container >

            {/* Testing Early */}
            < Container className='background-01 home-section' fluid >
                <h2 className="text-center" style={{ color: '#4B0082', fontWeight: 'bold', marginBottom: '3rem' }}>
                    Testing Early
                </h2>
                <Row className='ashera-row align-items-center'>
                    {/* Left Column */}
                    <Col md={6} className="d-flex justify-content-center align-items-center text-center">
                        <Image rounded src={womenAssets['other/pregnancy']} alt="Card 1" style={{ maxWidth: '100%' }} />
                    </Col>

                    {/* Right Column: Chat */}
                    <Col md={4} >
                        <p>Taking proactive steps before pregnancy, such as thorough health testing, is one of the most beneficial actions you can take. Toxins like "forever chemicals" can linger in your body for years, silently impacting your well-being. By identifying these risks early, you can ensure a healthier start for yourself and your future children.</p>

                        <p>Our modern lifestyle exposes us to many risks that previous generations never faced. Women with higher levels of forever chemicals may experience up to a 40% reduction in fertility. These chemicals are also linked to increased pregnancy risks, including hypertensive disorders, gestational diabetes, and developmental issues in children.</p>

                        <p>Microplastics have been found in human placentas, indicating that these particles can cross the placental barrier and potentially affect fetal development. Prenatal exposure to pesticides is associated with cognitive deficits in children, including attention-related behavioral issues. Additionally, chemicals like bisphenols and phthalates can interfere with the endocrine system, leading to adverse effects on pregnancy and fetal development.</p>

                        <p>By testing for these toxins before pregnancy, you can take proactive steps to mitigate these risks and promote a healthier environment for both you and your future children.</p>

                    </Col>
                </Row>
            </Container >

        </>
    );
};

export default Women;
