import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

interface Tab {
    key: string;
    label: string;
    title: string;
    subtitle: string;
    content: (props: { tabKey: string }) => React.ReactNode;
}

const CustomTabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState('toxins');
    const [tabHeight, setTabHeight] = useState<number>(0);
    const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});

    const tabs: Tab[] = [
        {
            key: 'toxins',
            label: 'Toxin Impact',
            title: 'Toxin Impact',
            subtitle: 'Alex and Mia',
            content: ({ tabKey }) => (
                <>
                    <p style={{ color: '#4B0082' }}>
                        Alex and Mia identified trace levels of harmful substances that other tests missed, as well as the impact and likely sources of exposure. They optimized their detoxification processes and developed strategies to minimize future toxin exposure.
                    </p>
                    <Accordion
                        activeKey={openAccordions[tabKey] ? '0' : null}
                        onSelect={(eventKey) => {
                            setOpenAccordions(prevState => ({
                                ...prevState,
                                [tabKey]: eventKey === '0'
                            }));
                        }}
                    >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Learn More</Accordion.Header>
                            <Accordion.Body>
                                <div style={{ color: '#4B0082' }}>
                                    <strong>Go beyond standard tests</strong> by detecting subtle chemical signatures and linking them to likely sources of exposure. Take a proactive step to reduce toxin-related health risks.

                                    <br /><br /><strong>Areas of Focus:</strong>
                                    <ul>
                                        <li><strong>Hormone Disruptors</strong>
                                            <ul>
                                                <li>PFAS (Forever Chemicals)</li>
                                                <li>BPA</li>
                                                <li>Phthalates</li>
                                            </ul>
                                        </li>
                                        <li><strong>Pesticides</strong>
                                            <ul>
                                                <li>Glyphosate</li>
                                                <li>DDT</li>
                                            </ul>
                                        </li>
                                        <li><strong>Industrial Pollutants</strong>
                                            <ul>
                                                <li>VOCs</li>
                                                <li>Heavy Metals</li>
                                            </ul>
                                        </li>
                                        <li><strong>Mold Mycotoxins</strong></li>
                                        <li><strong>Household Contaminants & Additives</strong></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            ),
        },
        {
            key: 'metabolites',
            label: 'Undiagnosed Symptoms',
            title: 'Undiagnosed Symptoms',
            subtitle: 'Sam and Grace',
            content: ({ tabKey }) => (
                <>
                    <p style={{ color: '#4B0082' }}>
                        Sam and Grace bonded over their desire to uncover the source of their undiagnosed symptoms that were stumping doctors. They discovered how metabolomics detects biochemical imbalances, nutrient deficiencies, toxin impacts, and sources of inflammation, providing insights for personalized, preventive care that standard tests often miss.
                    </p>
                    <Accordion
                        activeKey={openAccordions[tabKey] ? '0' : null}
                        onSelect={(eventKey) => {
                            setOpenAccordions(prevState => ({
                                ...prevState,
                                [tabKey]: eventKey === '0'
                            }));
                        }}
                    >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Learn More</Accordion.Header>
                            <Accordion.Body>
                                <div style={{ color: '#4B0082' }}>
                                    <strong>Uncovering Hidden Causes of Symptoms:</strong>
                                    <ul>
                                        <li>Fatigue & Energy Issues</li>
                                        <li>Digestive Problems</li>
                                        <li>Headaches & Brain Fog</li>
                                    </ul>

                                    <strong>Find Health Risks Before Symptoms Appear:</strong>
                                    <ul>
                                        <li>Comprehensive Health Snapshot</li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            ),
        },
        {
            key: 'testing',
            label: 'Reproductive Health',
            title: 'Reproductive Health',
            subtitle: 'Emily',
            content: ({ tabKey }) => (
                <>
                    <p style={{ color: '#4B0082' }}>
                        Emily optimized her fertility, prepared for a healthy pregnancy, and received personalized support throughout motherhood. Her mother also navigated menopause more comfortably, managing symptoms and reducing long-term health risks with personalized guidance.
                    </p>
                    <Accordion
                        activeKey={openAccordions[tabKey] ? '0' : null}
                        onSelect={(eventKey) => {
                            setOpenAccordions(prevState => ({
                                ...prevState,
                                [tabKey]: eventKey === '0'
                            }));
                        }}
                    >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Learn More</Accordion.Header>
                            <Accordion.Body>
                                <div style={{ color: '#4B0082' }}>
                                    <strong>Go beyond traditional testing</strong> to detect subtle imbalances, enzyme dysfunctions, and early changes in hormone and metabolic pathways.

                                    <br /><br /><strong>Areas of Focus:</strong>
                                    <ul>
                                        <li><strong>Fertility & Reproduction</strong>
                                            <ul>
                                                <li>Ovarian Reserve & Function</li>
                                                <li>Endometriosis</li>
                                                <li>PCOS</li>
                                            </ul>
                                        </li>
                                        <li><strong>Pregnancy & Early Development</strong>
                                            <ul>
                                                <li>Gestational Diabetes</li>
                                                <li>Preeclampsia</li>
                                                <li>Fetal Development & Maternal Health</li>
                                            </ul>
                                        </li>
                                        <li><strong>Hormone & Menstrual Cycle Disorders</strong></li>
                                        <li><strong>Chronic Disease & Inflammation</strong></li>
                                        <li><strong>Metabolic Shifts & Menopause</strong></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            ),
        },
    ];

    // Refs for each tab content
    const tabRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const isAnyAccordionOpen = Object.values(openAccordions).some(Boolean);

    // // Effect to set the maximum height
    // useEffect(() => {
    //     const heights = Object.keys(tabRefs.current).map(key => {
    //         const el = tabRefs.current[key];
    //         return el ? el.offsetHeight : 0;
    //     });
    //     console.log('heights', heights)
    //     const maxHeight = Math.max(...heights);
    //     setTabHeight(maxHeight);
    // }, [tabs]);

    // // Auto-rotate tabs every x seconds
    // useEffect(() => {
    //     const tabKeys = tabs.map(tab => tab.key);
    //     let currentIndex = tabKeys.indexOf(activeTab);

    //     const interval = setInterval(() => {
    //         currentIndex = (currentIndex + 1) % tabKeys.length;
    //         setActiveTab(tabKeys[currentIndex]);
    //     }, 10000); // x seconds

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [activeTab, tabs]);

    useEffect(() => {
        const tabKeys = tabs.map(tab => tab.key);
        let currentIndex = tabKeys.indexOf(activeTab);
        let interval: NodeJS.Timeout | undefined;

        if (!isAnyAccordionOpen) {
            interval = setInterval(() => {
                currentIndex = (currentIndex + 1) % tabKeys.length;
                setActiveTab(tabKeys[currentIndex]);
            }, 10000); // x seconds
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [activeTab, tabs, isAnyAccordionOpen]);

    return (
        <Container className='background-01 home-section' fluid style={{ minHeight: '40vh' }}>
            <div className="ashera-row">
                {/* Indicators */}
                <div className="indicators" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    {tabs.map(tab => (
                        <button
                            key={tab.key}
                            className={activeTab === tab.key ? 'active' : ''}
                            onClick={() => setActiveTab(tab.key)}
                            style={{
                                padding: '10px 20px',
                                margin: '0 10px',
                                // backgroundColor: activeTab === tab.key ? 'var(--darker-purple)' : '#fff',
                                //color: activeTab === tab.key ? '#fff' : 'var(--darker-purple)',
                                // border: '1px solid var(--darker-purple)',
                                // borderRadius: '5px',
                                fontWeight: activeTab === tab.key ? 'bold' : '',
                                color: 'var(--darker-purple)',
                                backgroundColor: 'unset',
                                border: 'unset',
                                borderBottom: '1px solid var(--darker-purple)',
                                cursor: 'pointer'
                            }}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>

                {/* Content Container with Dynamic Height */}
                <div
                // style={{ height: tabHeight }}
                >
                    {tabs.map(tab => (
                        <div
                            key={tab.key}
                            ref={el => (tabRefs.current[tab.key] = el)}
                            style={{
                                position: activeTab === tab.key ? 'relative' : 'absolute',
                                visibility: activeTab === tab.key ? 'visible' : 'hidden',
                                top: 0,
                                left: 0,
                                width: '100%',
                                overflow: 'hidden'
                            }}
                        >
                            <Row className="align-items-stretch">
                                <Col md={6} className="text-center h-100 d-flex flex-column" style={{ padding: '20px' }}>
                                    <h6 style={{ color: '#4B0082', fontWeight: 'bold' }}>Voices</h6>
                                    <h3 style={{ color: '#4B0082', fontWeight: 'bold' }}>{tab.subtitle}</h3>
                                </Col>
                                <Col md={6} className="h-100 d-flex flex-column" style={{ padding: '20px' }}>
                                    {tab.content({ tabKey: tab.key })}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default CustomTabs;
