import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Image, Carousel, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { report, homeAssets } from '../assets'
import HomeChat from '../components/HomeChat';
import HomeVoices from '../components/HomeVoices';
import HexagonReport from '../components/HexagonReport'

const messages = [
    {
        id: 1,
        text: "I recently had a test that measured my PFAS levels.",
        time: "09:05 AM | Today",
        user: "user",
        isExpert: false,
    },
    {
        id: 2,
        text: "The results showed they were slightly elevated. What does this mean for my health?",
        time: "09:06 AM | Today",
        user: "user",
        isExpert: false,
    },
    {
        id: 3,
        text: "Hi, lightly elevated PFAS levels indicate that you have been exposed to these chemicals more than the average person. While this isn't an immediate cause for alarm, it is advisable to take steps to reduce exposure, as long-term exposure can have health impacts.",
        time: "09:07 AM | Today",
        user: "Expert",
        isExpert: true,
    },
    {
        id: 4,
        text: "How can I limit my exposure to PFAS?",
        time: "09:44 AM | Today",
        user: "user",
        isExpert: false,
    },
    {
        id: 5,
        text: "Based on your profile, you live in an area with high pollution levels. A good starting point would be to consider purchasing a water filter to reduce PFAS exposure.",
        time: "09:45 AM | Today",
        user: "Expert",
        isExpert: true,
    },
];

const CardsHorizontal = report['cards-horizontal-main'];
const CardsVertical = report['cards-vertical'];




const Home: React.FC = () => {

    return (
        <>
            {/* Hero */}
            <Container className='ombre-01 home-section' fluid >
                <Row className="ashera-row mt-5 align-items-center text-center">
                    <Col lg={6} className="order-lg-1 order-2" >
                        <h2 style={{ fontSize: '3rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Unlock Your Health's Hidden Signals</h2>
                        <p style={{ fontSize: '1.2rem', color: 'var(--darker-purple)' }}>
                            Uncover the impact of toxins & learn what your body’s biochemical signals reveal about your health
                        </p>
                        <Button target='_blank' variant="primary" size="lg"
                            className='cta-button'
                            href='https://forms.fillout.com/t/jeUeErZTb3us'>
                            Sign Up
                        </Button>
                    </Col>
                    <Col lg={6} className="order-lg-2 order-1 mb-4 mb-lg-0 text-center">
                        <Carousel className='hero-carousel'>
                            {Array.from({ length: 2 }).map((_, index) => (
                                <Carousel.Item key={index} >
                                    <Image
                                        src={homeAssets['carousel/' + index.toString()]}
                                        alt={`Health Illustration ${index}`}
                                        rounded
                                        className='hero-image'
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <CardsHorizontal
                            className='hero-cards-horizonal'
                        />
                    </Col>
                </Row>
            </Container>

            {/* <HexagonReport /> */}

            {/* Stories */}
            <HomeVoices />

            {/* Two Info Cards */}
            <Container className='background-01 home-section' fluid style={{ minHeight: '50vh' }}>
                <Row className="ashera-row justify-content-center">
                    <Col md={6} className="mb-4" >
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={homeAssets['icons/fingerprint']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>What Sets Us Apart?</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    At the forefront of health diagnostics, we excel in identifying health issues that conventional tests often miss or don't cover.
                                    Our advanced technology decodes your body’s unique biochemical markers, unveiling critical signals that lead to valuable health insights.
                                    Our tests offer:
                                </Card.Text>
                                <ul style={{ color: 'var(--darker-purple)', fontSize: '1rem', paddingLeft: '1rem' }}>
                                    <li><strong>At Home Convenience</strong> </li>
                                    <li><strong>Personalized Insights</strong></li>
                                    <li><strong>Next-Generation Technology</strong></li>
                                    <li><strong>Actionable Guidance</strong></li>
                                    <li><strong>Advanced Diagnostics</strong></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={homeAssets['icons/giftbag']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>What You Get</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Your Personalized Wellness Evaluation Report is designed to be the cornerstone of your health journey. Our comprehensive
                                    guide provides a deep dive into every aspect of your well-being, offering a tailored approach to understanding and improving
                                    your health. Here’s what you can expect:
                                </Card.Text>
                                <ul style={{ color: 'var(--darker-purple)', fontSize: '1rem', paddingLeft: '1rem' }}>
                                    <li><strong>Health Insights Summary</strong></li>
                                    <li><strong>Biomarker and Toxin Results</strong></li>
                                    <li><strong>Lifestyle Analysis</strong></li>
                                    <li><strong>Age & Health Profile</strong></li>
                                    <li><strong>Health Roadmap & Recommendations</strong></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >


            {/* Key Areas of Detection */}
            <Container className='background-01 home-section' fluid style={{ minHeight: 'unset' }}>
                <div className="text-center">
                    <Button target='_blank' variant="primary" size="lg"
                        className='cta-button'
                        style={{ marginBottom: '5rem' }}
                        href='https://forms.fillout.com/t/jeUeErZTb3us'>
                        Join Our Waitlist
                    </Button>
                </div>
                <h2 className="text-center" style={{ color: '#4B0082', fontWeight: 'bold', marginBottom: '3rem' }}>
                    Key Areas of Detection
                </h2>
                <Row className="ashera-row justify-content-center">
                    <Col md={3} lg={3} className="text-center mb-4">
                        <Image
                            src={homeAssets['areas_of_detection/toxins']} // Replace with your image URL
                            roundedCircle
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                        <h4 style={{ color: '#4B0082', fontWeight: 'bold', marginTop: '1rem' }}>Toxins & Chemicals Exposure</h4>
                        <p style={{ color: '#4B0082' }}>
                            Detect endocrine disruptors, heavy metals, and environmental pollutants that impact your health.
                        </p>
                    </Col>

                    <Col md={3} lg={3} className="text-center mb-4">
                        <Image
                            src={homeAssets['areas_of_detection/metabolic-health']} // Replace with your image URL
                            roundedCircle
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                        <h4 style={{ color: '#4B0082', fontWeight: 'bold', marginTop: '1rem' }}>Nutritional, Gut, and Metabolic Health</h4>
                        <p style={{ color: '#4B0082' }}>
                            Tailor your diet, improve your metabolism, gut health & digestion, enhance energy levels & build a stronger immune system.
                        </p>
                    </Col>

                    <Col md={3} lg={3} className="text-center mb-4">
                        <Image
                            src={homeAssets['areas_of_detection/longevity']} // Replace with your image URL
                            roundedCircle
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                        <h4 style={{ color: '#4B0082', fontWeight: 'bold', marginTop: '1rem' }}>Health, Aging, and Longevity</h4>
                        <p style={{ color: '#4B0082' }}>
                            Embrace a proactive approach to aging and longevity, focusing on overall well-being for a healthier, longer life.
                        </p>
                    </Col>

                    <Col md={3} lg={3} className="text-center mb-4">
                        <Image
                            src={homeAssets['areas_of_detection/women']} // Replace with your image URL
                            roundedCircle
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                        <h4 style={{ color: '#4B0082', fontWeight: 'bold', marginTop: '1rem' }}>Women's Health</h4>
                        <p style={{ color: '#4B0082' }}>
                            Support for women's unique health needs, including hormonal balance, reproductive health, and more.
                        </p>
                    </Col>
                </Row>
            </Container >


            {/* How it works */}
            <Container className='background-02 home-section' fluid style={{ minHeight: '70vh' }} >
                <Row className="align-items-center ashera-row">
                    {/* Left Side: SVG Cards */}
                    <Col md={6} className="d-flex flex-column align-items-center">
                        <CardsVertical style={{ maxHeight: '80vh' }} />
                    </Col>

                    {/* Right Side: Steps */}
                    <Col md={6}>
                        <h2 style={{ fontWeight: 'bold', color: '#fff', marginBottom: '2rem' }}>How It Works</h2>
                        <ol style={{ listStyleType: 'none', paddingLeft: 0, color: '#fff' }}>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'>1</span>
                                <div>
                                    <strong>Flexible DIY at Home or On the Go</strong>
                                    <p>Use our needle-free device at home or on the go. Choose pickup or drop-off options.</p>
                                </div>
                            </li>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'>2</span>
                                <div>
                                    <strong>A Comprehensive Snapshot</strong>
                                    <p>Complete a lifestyle assessment. You can also upload health data points like blood work (optional) or your DNA data file (optional).</p>
                                </div>
                            </li>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'>3</span>
                                <div>
                                    <strong>Personalized Insights</strong>
                                    <p>Your sample is analyzed by our lab. AI and human experts ensure accuracy and privacy. Then, receive a comprehensive report with diagnostic results and health insights.</p>
                                </div>
                            </li>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'>4</span>
                                <div>
                                    <strong>Consultation</strong>
                                    <p>Share your Ashera Health report with your doctor or health advisors to explore personalized recommendations and care options. Alternatively, schedule a consultation with Ashera Health for further guidance and next steps.</p>
                                </div>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>

            {/* Chat */}
            <Container className='background-01 home-section' fluid >
                <Row className='ashera-row'>
                    {/* Left Column */}
                    <Col md={6} className="d-flex justify-content-center align-items-center text-center">
                        <div> <h2 style={{ color: '#4B0082', fontWeight: 'bold' }}>Your Health Assistant</h2>
                            <p>Whether you’re a healthcare professional or someone prioritizing your well-being, explore Ashera Health’s services. We empower you with the knowledge you need to take the next step. Start the conversation today!
                                {/* <ul>
                                    <li>Fertility & Reproductive Health</li>
                                    <li>Toxins & Wellness</li>
                                    <li>Live Healthier, Longer</li>
                                    <li>Unravel Hard-to-Diagnose Symptoms</li>
                                </ul> */}

                            </p>
                        </div>
                    </Col>

                    {/* Right Column: Chat */}
                    <Col md={6} >
                        <HomeChat messages={messages} />
                    </Col>
                </Row>
            </Container>



        </>
    );
};

export default Home;
