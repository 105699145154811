// Header.tsx
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaLinkedin, FaTiktok, FaInstagram, FaXTwitter } from 'react-icons/fa6';


const Header: React.FC = () => {
    return (
        <Container fluid className='background-02 home-section' style={{ minHeight: 'unset' }}>
            <Row className="justify-content-center text-center ashera-row">
                <Col md={6}>
                    <h2 style={{ fontWeight: 'bold', color: '#fff' }}>Connect with Us</h2>
                    <Button target='_blank' href='mailto:jlawy@asherahealth.com' variant="light" style={{ borderRadius: '20px', padding: '0.5rem 2rem', marginTop: '1rem' }}>
                        Contact
                    </Button>
                </Col>
            </Row>
            <Row className="justify-content-center text-center mt-4">
                <Col>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginBottom: '1rem' }}>
                        <a href="https://linkedin.com/company/ashera-health" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin color="#fafafa" size="2em" />
                        </a>
                        <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                            <FaTiktok color="#fafafa" size="2em" />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <FaInstagram color="#fafafa" size="2em" />
                        </a>
                        <a href="https://www.x.com" target="_blank" rel="noopener noreferrer">
                            <FaXTwitter color="#fafafa" size="2em" />
                        </a>
                    </div>
                    <p style={{ fontSize: '0.875rem', color: '#ccc' }}>© {new Date().getFullYear()} Asherah Health. All rights reserved.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
