import React from 'react';
import { Container, Row, Col, Button, Card, Image, Carousel, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { scienceAssets, report } from '../assets'
import { logo } from '../assets'

const CardsHorizontal = report['cards-horizontal'];
const CardsVertical = report['cards-vertical'];
const Science: React.FC = () => {
    return (
        <>
            {/* Hero */}
            <Container className='ombre-01 home-section' fluid>
                <Row className="ashera-row mt-5 align-items-center text-center">
                    <Col lg={6} className="order-lg-1 order-2" >
                        <h2 style={{ fontSize: '3rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Revolutionizing Health Insights</h2>
                        <p style={{ fontSize: '1.2rem', color: 'var(--darker-purple)' }}>
                            Unlock a complete picture of your health with our metabolite analysis, revealing how your body is performing and identifying any hidden imbalances or deficiencies.
                        </p>
                        <Button target='_blank' variant="primary" size="lg" className='cta-button' href='https://forms.fillout.com/t/jeUeErZTb3us'>
                            Sign Up
                        </Button>
                    </Col>
                    <Col lg={6} className="order-lg-2 order-1 mb-4 mb-lg-0 text-center">
                        <Carousel className='hero-carousel'>
                            {Array.from({ length: 2 }).map((_, index) => (
                                <Carousel.Item key={index} >
                                    <Image
                                        src={scienceAssets['carousel/' + index.toString()]}
                                        alt={`Health Illustration ${index}`}
                                        rounded
                                        className='hero-image'
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <CardsHorizontal

                            className='hero-cards-horizonal'
                        />
                    </Col>
                </Row>
            </Container>

            {/* Two Info Cards */}
            <Container className='background-01 home-section' fluid style={{ minHeight: '50vh' }}>
                <Row className="ashera-row justify-content-center">
                    <Col md={6} className="mb-4" >
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={scienceAssets['icons/molecule']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Metabolomics</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    A technology transforming our understanding & management of health by offering unprecedented insights into the complex biochemical processes within our bodies. This cutting-edge field focuses on the comprehensive analysis of metabolites — small molecules crucial for various health-related functions. Imagine your body as a bustling city, with your bloodstream acting as a vast network of highways. These highways transport essential substances like nutrients, oxygen, hormones, and remove toxins and metabolic waste. At Ashera Health, we harness the power of metabolomics to unlock the secrets hidden in your blood.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={scienceAssets['icons/sampling']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Sampling</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>

                                    A blood sample is a treasure trove of information about your body's metabolic processes. It contains a diverse array of metabolites—including amino acids, lipids, sugars, and nucleotides—that each play a crucial role in maintaining your health. By analyzing these metabolites, we can gain a comprehensive snapshot of your well-being, revealing how effectively your body is functioning and highlighting any imbalances or deficiencies. Collecting a blood sample is now easier than ever with the convenient kit we provide.
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

            {/* Metabolites */}
            <Container className='background-01 home-section' fluid style={{ minHeight: '85vh' }}>
                <h2 className="text-center" style={{ color: '#4B0082', fontWeight: 'bold', marginBottom: '3rem' }}>
                    What Metabolites Can Reveal
                </h2>
                <Row className='ashera-row align-items-center'>
                    {/* Left Column */}
                    <Col md={6} className="d-flex justify-content-center align-items-center text-center">
                        <Image rounded src={scienceAssets['other/metabolites']} alt="Card 1" style={{ maxWidth: '100%' }} />
                    </Col>

                    {/* Right Column: Chat */}
                    <Col md={4} >
                        <p>The presence or absence of specific metabolites in your blood can indicate serious health issues. Elevated levels of certain metabolites may suggest early stages of cancer, while others can reveal the risk of developing Alzheimer's disease or cardiovascular conditions. Toxic metabolites might indicate exposure to harmful environmental toxins or drugs, leading to severe health complications if not addressed. Understanding these dangers empowers you to take proactive measures to protect your health.</p>
                        <p>Using advanced AI tools and diagnostic toos like mass spectrometry, we precisely identify and quantify these metabolites, allowing us to detect early signs of chronic diseases, assess organ function, and understand hormonal balances. This detailed analysis aids in diagnosing existing conditions and predicting future health risks, enabling proactive and personalized health management. With metabolomics, we empower you with the knowledge needed to optimize your health and well-being.</p>
                    </Col>
                </Row>
            </Container>

            {/* Technology Overview */}
            <Container className='background-02 home-section' fluid style={{ minHeight: '70vh' }} >
                <Row className="align-items-center ashera-row">
                    {/* Left Side: SVG Cards */}
                    <Col md={6}>
                        <h2 style={{ fontWeight: 'bold', color: '#fff', marginBottom: '2rem' }}>Technology Overview </h2>
                        <ol style={{ listStyleType: 'none', paddingLeft: 0, color: '#fff' }}>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'><Card.Img
                                    src={logo['darker-purple']}
                                    style={{ maxWidth: '40px' }}
                                    alt="Icon"
                                /></span>
                                <div>
                                    <strong>Metabolomics</strong>
                                    <p>Offering a detailed snapshot of your biochemical activity to reveal health markers and potential risks</p>
                                </div>
                            </li>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'><Card.Img
                                    src={logo['darker-purple']}
                                    style={{ maxWidth: '40px' }}
                                    alt="Icon"
                                /></span>
                                <div>
                                    <strong>Mass Spectrometry</strong>
                                    <p>High-resolution diagnostic technology measuring metabolites in your blood to identify key biomarkers.</p>
                                </div>
                            </li>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'><Card.Img
                                    src={logo['darker-purple']}
                                    style={{ maxWidth: '40px' }}
                                    alt="Icon"
                                /></span>
                                <div>
                                    <strong>AI Integration</strong>
                                    <p>Advanced AI analyzes metabolomic data alongside lifestyle, genetic, and environmental factors for comprehensive insights.</p>
                                </div>
                            </li>
                            <li className="mb-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <span className='step-number-style'><Card.Img
                                    src={logo['darker-purple']}
                                    style={{ maxWidth: '40px' }}
                                    alt="Icon"
                                /></span>
                                <div>
                                    <strong>Research Backed</strong>
                                    <p>Grounded in extensive academic and clinical research, continuously updated with the latest findings.</p>
                                </div>
                            </li>

                        </ol>
                    </Col>

                    {/* Right Side: Steps */}
                    <Col md={6} className="d-flex flex-column align-items-center">
                        <CardsVertical style={{ maxHeight: '80vh' }} />
                    </Col>

                </Row>
            </Container>

            {/* Comprehensive */}
            <Container className='background-01 home-section' fluid style={{ minHeight: '40vh' }}>
                <Row className="ashera-row justify-content-center">
                    <Col md={12} className="mb-4" >
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={logo['darker-purple']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Comprehensive Range of Biomarkers</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    We offer an extensive array of over 500 key biomarkers, ensuring a holistic approach to your well-being. Our integration of additional wellness data points provides a comprehensive view of your health. Stay informed with the latest updates and additions to our growing list of biomarkers. Contact us today to explore the full spectrum of biomarkers available to you and take a proactive step towards optimal health.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

            {/* Roadmap */}
            <Container className='background-02 home-section roadmap' fluid style={{ minHeight: '50vh' }}>
                <h2 className="text-center" style={{ color: '#fafafa', fontWeight: 'bold', marginBottom: '3rem' }}>
                    Roadmap
                </h2>
                <Row className="ashera-row justify-content-center">
                    <Col md={12} className="mb-4" >
                        <Card className="h-100" style={{ border: '1px solid #fafafa', borderRadius: '10px', padding: '2rem', backgroundColor: 'var(--darker-purple)' }}>
                            {/* <Card.Body>
                                <Card.Img
                                    src={logo['darker-purple']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Comprehensive Range of Biomarkers</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    We offer an extensive array of over 500 key biomarkers, ensuring a holistic approach to your well-being. Our integration of additional wellness data points provides a comprehensive view of your health. Stay informed with the latest updates and additions to our growing list of biomarkers. Contact us today to explore the full spectrum of biomarkers available to you and take a proactive step towards optimal health.
                                </Card.Text>
                            </Card.Body> */}

                            <Table striped bordered hover responsive style={{ color: '#fafafa', borderColor: 'transparent' }}>
                                <thead>
                                    <tr style={{ borderBottom: '1px solid #fafafa' }}>
                                        <th><h2>Phase</h2></th>
                                        <th><h2>Features</h2></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td rowSpan={3} style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>1</td>
                                        <td>Toxins: Detection of endocrine disruptors, heavy metals, VOCs, pesticides, mycotoxins (mold), PFAS (forever chemicals), pharmaceutical residues, and dioxins, furans, and more...</td>
                                        <td>
                                            Nutritional, Gut and Metabolic Health: Personalized dietary recommendations and insights into gut health.
                                        </td>
                                        <td>Health, Aging & Longevity: Key health, aging, gut health, and disease prevention indicators.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} >Maternal Health: Identifying toxins and nutritional biomarkers essential for women planning a pregnancy.</td>
                                    </tr>
                                    <tr style={{ borderBottom: '1px solid #fafafa' }}>

                                    </tr>
                                    <tr style={{ borderBottom: '1px solid #fafafa' }}>
                                        <td style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>2</td>
                                        <td>Disease Prevention and Early Detection.</td>
                                        <td>Inflammatory and Autoimmune Disease Management.</td>
                                        <td>Performance & Personalized Medicine.</td>
                                    </tr>

                                </tbody>
                            </Table>

                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    );
};

export default Science;
