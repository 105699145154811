import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { logo } from '../assets';

const Header: React.FC = () => {
    return (
        <Navbar
            expand="lg"
            style={{
                padding: '1rem 1rem 1rem 2rem',
                backgroundColor: 'var(--faint-purple)',
                paddingLeft: 'var(--global-margin-left)',
                paddingRight: 'var(--global-margin-left)',
            }}
        >
            <Navbar.Brand as={NavLink} to="/">
                <Image
                    src={logo['darker-purple-with-font']}
                    alt="Ashera Logo"
                    style={{ height: '40px' }}
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link as={NavLink} to="/" end>
                        Home
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/women">
                        Women
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/science">
                        Tech and Science
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/about">
                        About
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/privacy">
                        Privacy
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
