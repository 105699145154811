// pages/About.tsx
import React from 'react';
import { Container, Row, Col, Button, Carousel, Image, Card } from 'react-bootstrap';
import { aboutAssets, report } from '../assets'


const CardsHorizontal = report['cards-horizontal'];
const About: React.FC = () => {
    return (
        <>
            <Container className='ombre-01 home-section' fluid>
                <Row className="ashera-row mt-5 align-items-center text-center">
                    <Col lg={6} className="order-lg-1 order-2" >
                        <h2 style={{ fontSize: '3rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Post Theory Science</h2>
                        <p style={{ fontSize: '1.2rem', color: 'var(--darker-purple)' }}>
                            We pride ourselves on being at the forefront of metabolomics and metabolomics data analysis, leveraging the power of artificial intelligence to deliver unparalleled insights and solutions.
                            Get early access
                        </p>
                        <Button target='_blank' variant="primary" size="lg" className='cta-button' href='https://forms.fillout.com/t/jeUeErZTb3us'>
                            Sign Up
                        </Button>
                    </Col>
                    <Col lg={6} className="order-lg-2 order-1 mb-4 mb-lg-0 text-center">
                        <Carousel className='hero-carousel'>
                            {Array.from({ length: 1 }).map((_, index) => (
                                <Carousel.Item key={index} >
                                    <Image
                                        src={aboutAssets['carousel/' + index.toString()]}
                                        alt={`Health Illustration ${index}`}
                                        rounded
                                        className='hero-image'
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <CardsHorizontal
                            className='hero-cards-horizonal'
                        />
                    </Col>
                </Row>
            </Container>
            <Container className='background-01 home-section' fluid style={{ minHeight: '50vh' }}>
                <h2 className="text-center" style={{ color: '#4B0082', fontWeight: 'bold', marginBottom: '3rem' }}>
                    Who We Are
                </h2>
                <Row className="ashera-row justify-content-center">
                    <Col md={6} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={aboutAssets['icons/molecule']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Expertise in Metabolomics</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Our team consists of seasoned experts in the field of metabolomics, dedicated to understanding the intricate networks of metabolites within biological systems. We employ cutting-edge technologies and methodologies to measure and analyze small molecules, helping to uncover the biochemical pathways and mechanisms underlying various physiological and pathological conditions. Our expertise spans from experimental design to the interpretation of complex metabolomic data, ensuring that we provide comprehensive and accurate insights tailored to your specific needs.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={aboutAssets['icons/analysis']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Advanced Metabolomics Data Analysis</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Data analysis is a critical component of our metabolomics services. We utilize state-of-the-art computational tools and techniques to process and interpret large datasets, identifying key biomarkers and metabolic pathways. Our team is proficient in a range of analytical methods, including mass spectrometry and nuclear magnetic resonance spectroscopy, allowing us to deliver precise and reliable results. We also specialize in deisotoping and clustering of mz and intensity data arrays, retaining singletons to ensure comprehensive data analysis.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={aboutAssets['icons/brain']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Artificial Intelligence Integration</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>

                                    Artificial intelligence (AI) is at the core of our data analysis capabilities. By integrating AI and machine learning algorithms, we enhance our ability to detect patterns, predict outcomes, and generate actionable insights from metabolomic data. Our AI-driven approach allows us to handle vast amounts of data with speed and accuracy, uncovering hidden correlations and trends that may not be apparent through traditional methods. This innovative integration of AI ensures that our clients receive the most advanced and insightful analysis possible.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="h-100" style={{ border: '1px solid var(--darker-purple)', borderRadius: '10px', padding: '2rem', backgroundColor: '#fff' }}>
                            <Card.Body>
                                <Card.Img
                                    src={aboutAssets['icons/fingerprint']}
                                    style={{ maxWidth: '40px', marginBottom: '1rem' }}
                                    alt="Icon"
                                />
                                <Card.Title style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--darker-purple)' }}>Why Choose Us?</Card.Title>
                                <Card.Text style={{ color: 'var(--darker-purple)', fontSize: '1rem' }}>
                                    Choosing Asherah means partnering with a team of experts who are passionate about advancing the field of metabolomics and data analysis. Our commitment to excellence, combined with our innovative use of artificial intelligence, positions us as leaders in the industry. We are dedicated to providing high-quality, reliable, and actionable insights that drive scientific discovery and support the development of new therapies and solutions. Trust us to be your partners in unlocking the full potential of metabolomics through expert data analysis and the power of artificial intelligence.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>



                </Row>
            </Container >
        </>
    );
};

export default About;
