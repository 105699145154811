// src/assets.ts
import { loadAssets } from './utils/loadAssets';

const homeContext = require.context('./assets/home', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const homeAssets = loadAssets(homeContext);

const logoContext = require.context('./assets/logo', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const logo = loadAssets(logoContext);

const reportContext = require.context('./assets/report', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const report = loadAssets(reportContext);

const womenContext = require.context('./assets/women', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const womenAssets = loadAssets(womenContext);

const scienceContext = require.context('./assets/science', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const scienceAssets = loadAssets(scienceContext);

const aboutContext = require.context('./assets/about', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const aboutAssets = loadAssets(aboutContext);

